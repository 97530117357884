<script setup lang="ts">

import {CmsBlock} from "@shopware-pwa/types";
import {getSeoUrl} from "@shopware-pwa/api-client";

const category = inject("category", ref(null));
const {apiInstance} = useShopwareContext();
const breadcrumbs = ref([]);

const props = defineProps<{
    content: CmsBlock;
}>();

onMounted(async () => {
    if (!category.value) {
        return;
    }
    let pathIds = category.value.path.split("|");
    pathIds = pathIds.filter((id) => id !== "");
    if (pathIds.length < 2) {
        return;
    }

    await getSeoUrl(
        {
            filter: [
                {
                    type: "equalsAny",
                    field: "foreignKey",
                    value: pathIds
                }
            ]
        },
        apiInstance
    ).then((result) => {
        if (!result || !result.elements) {
            return;
        }
        breadcrumbs.value = pathIds.map((id) => {
            return result.elements.find((element) => element.foreignKey === id);
        });
    });
});
</script>
<template>
<div class="cms-block-breadcrumbs">
    <nav aria-label="breadcrumb" class="inline-block">
        <ol class="breadcrumbs flex px-5 md:px-0 mt-[15px] lg:mt-[25px] mb-0 flex-row basis-12/12 text-brand-darkGray text-[14px] md:text-[15px]"
            itemscope
            itemtype="https://schema.org/BreadcrumbList">
            <li
                itemscope
                itemprop="itemListElement"
                itemtype="https://schema.org/ListItem"
                class="list-none flex items-center text-brand-darkGray">
                <NuxtLink
                    to="/"
                    class="pr-1"
                    title="Forside"
                    itemprop="item"
                >
                    <span class="breadcrumb-title text-brand-darkGray" itemprop="name">Forside</span>
                </NuxtLink>
                <meta itemprop="position" content="1"/>
                <span> / </span>
            </li>
            <template v-for="(breadcrumb, key) in breadcrumbs">
                <li
                    v-if="breadcrumb && key !== 0"
                    itemscope
                    itemprop="itemListElement"
                    itemtype="https://schema.org/ListItem"
                    class="list-none flex items-center px-1 text-brand-darkGray"
                >
                    <NuxtLink
                        :to="'/' + breadcrumb.seoPathInfo"
                        class="pr-1"
                        :title="category.breadcrumb[key]"
                        itemscope
                        itemtype="https://schema.org/WebPage"
                        itemprop="item"
                        :itemid="'/' + breadcrumb.seoPathInfo"
                    >
                        <span class="breadcrumb-title text-brand-darkGray" itemprop="name">{{
                                category.breadcrumb[key]
                            }}</span>
                    </NuxtLink>
                    <meta itemprop="position" :content="key + 1" />
                    <span> / </span>
                </li>
            </template>
            <li
                v-if="category"
                itemscope
                itemprop="itemListElement"
                itemtype="https://schema.org/ListItem"
                class="list-none flex items-center pl-1 text-brand-darkGray"
            >
                <NuxtLink
                    to="#"
                    :title="category.name"
                    itemprop="item"
                >
                    <span class="breadcrumb-title text-brand-darkGray" itemprop="name">{{ category.name }}</span>
                </NuxtLink>
                <meta itemprop="position" :content="(breadcrumbs.length > 0 ? breadcrumbs.length : 1) + 1" />
            </li>
        </ol>
    </nav>
</div>
</template>
